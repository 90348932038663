// Utilities
export const constants = {
  MINIMUM_VALUE: 0,
  WEIGHT_MINIMUM_VALUE: -1,
};
export function validateDimension(
  inputValue,
  inputId,
  errMsgElement,
  fieldName,
  MIN_VALUE,
) {
  if (!inputValue || inputValue <= MIN_VALUE) {
    errMsgElement.innerHTML = `Please enter a valid ${fieldName}.`;
    document.querySelector(`#${inputId}`).focus();
    document.querySelector(`#${inputId}`).classList.add('is-invalid');
    return false;
  } else {
    errMsgElement.textContent = '';
    document.querySelector(`#${inputId}`).classList.remove('is-invalid');
    document.querySelector(`#${inputId}`).classList.add('is-valid');
    return true;
  }
}

export const displayAlertMessage = (
  message,
  messageType,
  alertPrimaryTextContainer,
  alertSecondaryTextContainer,
  alertContainerWrapper,
) => {
  if (messageType == 'success') {
    alertContainerWrapper.classList.add('alert-success', 'show');
    alertPrimaryTextContainer.innerText = 'Success';
  } else {
    alertContainerWrapper.classList.add('alert-danger', 'show');
    alertPrimaryTextContainer.innerText = 'Error';
  }
  alertContainerWrapper.removeAttribute('hidden');
  alertSecondaryTextContainer.innerText = message;
};
// Function to extract dimensions(length, width and height) from the selected text
export function extractDimensions(selectedText) {
  if (
    selectedText === '---------' ||
    selectedText == null ||
    selectedText == ''
  ) {
    return {
      length: null,
      width: null,
      height: null,
    };
  }

  const pattern = /\d+(\.\d+)?/g;
  const numbersWithDecimals = selectedText.match(pattern);

  if (!numbersWithDecimals) {
    return {
      length: null,
      width: null,
      height: null,
    };
  }

  const dimensions = numbersWithDecimals.map(num => parseFloat(num));

  return {
    length: dimensions[0],
    width: dimensions[1] || null,
    height: dimensions[2] || null,
  };
}

export function autofillDimensions(
  selectedText,
  constants,
  validators,
  inputsWithErrorElements,
) {
  // Extract dimensions from the selected text
  const dimensions = extractDimensions(selectedText);

  // Set the input values to the extracted dimensions
  constants.lengthInput.value =
    dimensions.length !== null ? dimensions.length : '';
  constants.widthInput.value =
    dimensions.width !== null ? dimensions.width : '';
  constants.heightElement.value =
    dimensions.height !== null ? dimensions.height : '';
  // call validators
  Object.values(validators).map(validator =>
    validator(inputsWithErrorElements),
  );
}

export function validateNewPrice(newPriceInput, newPriceInputValueErrMsg) {
  if (newPriceInput.hidden) {
    return true; // Skip validation if input is hidden
  }

  const newPriceInputValue = newPriceInput.value;

  if (
    !newPriceInputValue ||
    newPriceInputValue === '' ||
    isNaN(newPriceInputValue)
  ) {
    displayError(
      newPriceInput,
      newPriceInputValueErrMsg,
      'Please Enter the new Total price.',
    );
    return false;
  }

  if (newPriceInputValue < constants.MINIMUM_VALUE) {
    displayError(
      newPriceInput,
      newPriceInputValueErrMsg,
      `Price must be greater than or equal to the minimum value.${constants.MINIMUM_VALUE}`,
    );
    return false;
  }

  displaySuccess(newPriceInput, newPriceInputValueErrMsg);
  return true;
}

function displayError(input, errorMsg, message) {
  errorMsg.textContent = message;
  input.classList.add('is-invalid');
  input.focus();
}

function displaySuccess(input, newPriceInputValueErrMsg) {
  input.classList.remove('is-invalid');
  input.classList.add('is-valid');
  newPriceInputValueErrMsg.innerHTML = '';
}

export function addValueLocalStorage(fieldName, value) {
  let parcelItemData = localStorage.getItem('parcelItemData');
  if (!parcelItemData) {
    parcelItemData = {};
  } else {
    parcelItemData = JSON.parse(parcelItemData);
  }
  parcelItemData[fieldName] = value;
  // Store the value in local storage
  localStorage.setItem('parcelItemData', JSON.stringify(parcelItemData));
}

export function displayInputErrors(inputElement, message, errMsgElement) {
  inputElement.classList.add('is-invalid');
  errMsgElement.classList.add('text-danger');
  errMsgElement.innerText = message;
  errMsgElement.removeAttribute('hidden');
}
export function hideInputErrors(inputElement, errMsgElement) {
  inputElement.classList.remove('is-invalid');
  errMsgElement.classList.remove('text-danger');
  errMsgElement.innerText = '';
  errMsgElement.setAttribute('hidden', true);
}

export async function validateAgainstCollectionQuantity(
  quantityInput,
  requestData,
  url,
  handlers,
  htmlObjects,
) {
  if (requestData.action_type == 'EDITING' && !requestData.line_item_id) {
    return false;
  }
  let errMsgElement = htmlObjects.errMsgElement;
  let response = await handlers.postDataFunc(requestData, htmlObjects, url);
  let responseData = await response.json();
  if (response.ok) {
    handlers.hideInputErrors(quantityInput, errMsgElement);
    return true;
  } else {
    //NOTE: Consider serializer validation errors from backend , quantity validation errors
    let errorMessage = responseData.error;
    handlers.displayInputErrors(quantityInput, errorMessage, errMsgElement);
    return false;
  }
}

export function validateLength(obj) {
  let constants = obj;
  let lengthInputValue = Number(constants.lengthInput.value);
  let lengthInputID = constants.lengthInput.id;
  let lengthInputValueErrMsg = constants.lengthInputValueErrMsg;
  return validateDimension(
    lengthInputValue,
    lengthInputID,
    lengthInputValueErrMsg,
    'length',
    constants.MINIMUM_VALUE,
  );
}

export function validateWidth(obj) {
  let constants = obj;
  let widthInputValue = constants.widthInput.value;
  let widthInputID = constants.widthInput.id;
  let widthInputValueErrMsg = constants.widthInputValueErrMsg;
  return validateDimension(
    widthInputValue,
    widthInputID,
    widthInputValueErrMsg,
    'width',
    constants.MINIMUM_VALUE,
  );
}
export function validateWeight(obj) {
  let constants = obj;
  let weightInputValue = constants.weightInput.value;
  let weightInputID = constants.weightInput.id;
  let weightInputValueErrMsg = constants.weightInputValueErrMsg;
  return validateDimension(
    weightInputValue,
    weightInputID,
    weightInputValueErrMsg,
    'weight',
    constants.MINIMUM_VALUE,
  );
}
export function validateQuantity(obj) {
  let constants = obj;
  let quantityInputValue = parseInt(constants.quantity.value);
  let quantityInputID = constants.quantity.id;
  let quantityInputValueErrMsg = constants.quantityInputValueErrMsg;
  return validateDimension(
    quantityInputValue,
    quantityInputID,
    quantityInputValueErrMsg,
    'Quantity',
    constants.MINIMUM_VALUE,
  );
}

export function validateHeight(obj) {
  let constants = obj;
  let heightInputValue = constants.heightElement.value;
  let heightInputID = constants.heightElement.id;
  let heightInputValueErrMsg = constants.heightInputValueErrMsg;
  return validateDimension(
    heightInputValue,
    heightInputID,
    heightInputValueErrMsg,
    'height',
    constants.MINIMUM_VALUE,
  );
}

export function validateClassOfService(obj) {
  /** Validate class of service for orgs that have enabled it */
  let constants = obj;
  // no validations for organisations that have not enabled class of service
  if (constants.classOfServiceElement.type === 'hidden') {
    constants.classOfServiceInputValueErrMsg.textContent = '';
    return true;
  }

  let classOfServiceInputValue = constants.classOfServiceElement.value;
  let classOfServiceInputValueErrMsg = constants.classOfServiceInputValueErrMsg;
  if (
    !classOfServiceInputValue ||
    classOfServiceInputValue == '' ||
    classOfServiceInputValue == '---------'
  ) {
    classOfServiceInputValueErrMsg.innerHTML =
      'Class Of Service Field is required.';
    constants.classOfServiceElement.focus();
    return false;
  } else {
    classOfServiceInputValueErrMsg.textContent = '';
    return true;
  }
}

export const attachFetchPriceButtonEvents = (fetchPriceBtn, handlerFunc) => {
  fetchPriceBtn.addEventListener('click', handlerFunc);
};

export function extractCommonParcelData(obj) {
  let constants = obj;
  const weightInputValue = constants.weightInput.value.trim();
  const widthInputValue = constants.widthInput.value.trim();
  const heightInputValue = constants.heightElement.value.trim();
  const lengthInputValue = constants.lengthInput.value.trim();

  // Extract class of service if valid
  const classOfServiceInput =
    constants.classOfServiceElement &&
    constants.classOfServiceElement.value.trim() !== '---------'
      ? constants.classOfServiceElement.value.trim()
      : '';

  return {
    weight: parseFloat(weightInputValue),
    width: parseFloat(widthInputValue),
    height: parseFloat(heightInputValue),
    length: parseFloat(lengthInputValue),
    class_of_service_id: classOfServiceInput,
  };
}

// Function to extract data required to create a line item
export function extractLineItemData(obj) {
  let constants = obj;
  const commonParcelData = extractCommonParcelData(constants);

  return {
    ...commonParcelData,
    saveAsDraft: true,
    saveAndEditPrice: false,
    action_type: 'CREATING',
    line_item_id: null,
    sku_id: '',
    unit_price: 0,
    tax_amount: 0,
    total_price: 0,
    rate_card_id: '',
    rating_table_id: '',
    skuTaxId: '',
  };
}

// Function to extract data required to fetch the price
export function extractPriceFetchRequestData(obj) {
  let constants = obj;
  const commonParcelData = extractCommonParcelData(constants);
  const volume =
    commonParcelData.length * commonParcelData.width * commonParcelData.height;

  // Constructing the object without stringifying
  const requestData = {
    ...commonParcelData,
    sku_id: '',
    organisation_id: '',
    partner_id: constants.senderIdValue,
    quantity: constants.quantity.value,
    origin_id: constants.originIdValue,
    destination_id: constants.destinationIdValue,
    volume: volume,
  };

  // Returning both the stringified JSON and the object
  return {
    jsonString: JSON.stringify(requestData),
    objectData: requestData,
  };
}

// Function to fetch price data
export async function fetchPriceData(parcelformData, constants) {
  return await fetch(constants.FETCH_PRICE_URL, {
    method: 'POST',
    mode: 'cors',
    headers: {
      'X-CSRFToken': constants.csrf_token.value,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(parcelformData),
  });
}
export async function postDataFunc(parcelformData, constants, URL) {
  return await fetch(URL, {
    method: 'POST',
    mode: 'cors',
    headers: {
      'X-CSRFToken': constants.csrf_token.value,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(parcelformData),
  });
}

export async function fetchParcelPrice(
  parcelformData,
  constants,
  dependencies,
) {
  try {
    dependencies.showLoadingOverlay(constants);
    const response = await dependencies.fetchPriceData(
      parcelformData,
      constants,
    );
    const data = await response.json();
    dependencies.handlePriceResponse(
      response,
      data,
      constants,
      dependencies.handlePriceResponseDependencies,
    );
  } catch (error) {
    // case of network error or server error
    dependencies.handleResponseError(constants);
    console.error(error);
  } finally {
    dependencies.hideLoadingOverlay(constants);
  }
}

export function hideLoadingOverlay(constants) {
  constants.pricingSpinnerOverlay.setAttribute('hidden', true);
}

export function showLoadingOverlay(constants) {
  constants.pricingSpinnerOverlay.removeAttribute('hidden');
}

export function handlePriceResponse(response, data, constants, dependencies) {
  /**
   * Comment out and update tests(remove the .skip) when price fetching is restored
     if (data.message === 'error') {
      dependencies.displayErrorMessage(data.data, constants);
      dependencies.showNewPriceInputHideSubtotalText(constants);
      dependencies.toggleCollectionDeliveryButton(constants);
    } else {
      dependencies.populatePriceData(data, constants);
      if (
        constants.generateCollectionDeliveryBtn !== null &&
        constants.generateCollectionDeliveryBtn !== undefined
      ) {
        dependencies.toggleCollectionDeliveryButton(constants);
      }
      dependencies.hideNewPriceInput(constants);
      dependencies.hideErrorMessage(constants);
    }
   *
   */

  dependencies.showNewPriceInputHideSubtotalText(constants);
  dependencies.toggleCollectionDeliveryButton(constants);
}

export const showNewPriceInputHideSubtotalText = constants => {
  if (constants.newPriceInput !== null) {
    // clear original data(consider when user is continuously refetching)
    constants.newPriceInput.value = '';
    constants.taxText.innerHTML = '';
    constants.subtotalText.innerHTML = '';
    constants.rateCardText.innerHTML = '';
    constants.ratingTableText.innerHTML = '';

    constants.priceDisplayContainer.removeAttribute('hidden');
    constants.priceDisplayWrapper.removeAttribute('hidden');
    constants.newPriceInput.removeAttribute('hidden');
    constants.taxText.setAttribute('hidden', true);
    constants.rateCardAndTableTextParent.setAttribute('hidden', 'true');
    constants.newPriceInput.focus();
  } else {
    constants.priceDisplayWrapper.setAttribute('hidden', true);
    constants.taxText.removeAttribute('hidden');
  }
};

export function populatePriceData(data, constants) {
  // Clear any previous error message
  constants.errorMessageText.innerHTML = '';
  let quantityValue = constants.quantity.value;
  // Calculate subtotal
  const subtotal =
    (parseFloat(data.data.total_amount) - parseFloat(data.data.tax_amount)) *
    parseFloat(quantityValue);
  constants.subtotalText.innerText = `${data.data.currency} ${subtotal.toFixed(
    2,
  )}`;

  // Calculate tax
  const tax = data.data.tax_amount * quantityValue;
  constants.taxText.innerText = `${data.data.currency} ${tax.toFixed(2)}`;

  // Calculate total
  const total = parseFloat(data.data.total_amount) * parseFloat(quantityValue);
  constants.totalText.innerText = `${data.data.currency} ${total.toFixed(2)}`;
  constants.rateCardText.innerText = `${data.data.rate_card_name}`;
  constants.ratingTableText.innerText = `${data.data.rating_table_name}`;

  // Display price data
  constants.rateCardAndTableTextParent.removeAttribute('hidden');
  constants.priceDisplayContainer.removeAttribute('hidden');
}

export function toggleCollectionDeliveryButton(constants) {
  if (constants.orgCanEditPriceValue == 'False') {
    constants.generateCollectionDeliveryBtn.setAttribute('hidden', true);
    constants.saveAndEditPriceBtn.setAttribute('hidden', true);
  } else {
    constants.generateCollectionDeliveryBtn.removeAttribute('hidden');
    let newPriceInput = constants.newPriceInput;
    let isnewPriceInputHidden = newPriceInput.hidden;
    // when input price is hidden ==> there is a price fetched from ratecard , thats when button should appear
    if (isnewPriceInputHidden) {
      constants.saveAndEditPriceBtn.removeAttribute('hidden');
    }
  }
}

export function hideNewPriceInput(constants) {
  if (constants.newPriceInput) {
    constants.newPriceInput.setAttribute('hidden', true);
  }
}

export function displayErrorMessage(errorMessage, constants) {
  constants.errorMessageText.innerHTML = errorMessage;
  constants.errorMessageContainer.removeAttribute('hidden');
}

export function hideErrorMessage(constants) {
  constants.errorMessageContainer.setAttribute('hidden', true);
}

export function handleResponseError(constants) {
  let errorMessage =
    'An error occurred while processing your request, please try again.';
  constants.errorMessageText.innerHTML = errorMessage;
  constants.errorMessageContainer.removeAttribute('hidden');
  hideLoadingOverlay(constants);
}

export function attachNewPriceInputEvent(
  constants,
  handlers,
  taxHandlersAndArguments,
) {
  if (
    constants.newPriceInput !== null &&
    constants.newPriceInput !== undefined
  ) {
    constants.newPriceInput.addEventListener('input', function () {
      // update the tax amount and total amount
      handlers.updateTaxAndTotalAmount(constants, taxHandlersAndArguments);
      handlers.validateNewPrice(
        constants.newPriceInput,
        constants.newPriceInputValueErrMsg,
      );
    });
  }
}

export const updateTaxAndTotalAmount = (constants, taxHandlersAndArguments) => {
  let newPriceAmount = constants.newPriceInput.value;
  if (
    newPriceAmount.trim() == '' ||
    newPriceAmount == isNaN(newPriceAmount) ||
    newPriceAmount < constants.MINIMUM_VALUE
  ) {
    constants.taxText.innerText = '';
    constants.totalText.innerText = '';
    constants.subtotalText.innerText = '';
  } else {
    let multiplyQuantity = false;
    let taxAmount = taxHandlersAndArguments.calculateTax(
      newPriceAmount,
      multiplyQuantity,
      taxHandlersAndArguments.arguments,
    );
    constants.taxText.removeAttribute('hidden');
    constants.taxText.innerText = taxAmount;
    constants.subtotalText.innerText = (newPriceAmount - taxAmount).toFixed(2);
  }
};

export function calculateTax(newPriceAmount, multiplyQuantity, taxArguments) {
  let { VAT_PERCENTAGE, PERCENTAGE_BASE, quantityInput } = taxArguments;
  if (multiplyQuantity == true) {
    // for ratecards pricing (since it prices per item)
    return parseFloat(
      parseFloat(newPriceAmount) * VAT_PERCENTAGE * quantityInput.value.trim(),
    ).toFixed(2);
  } else {
    return parseFloat(
      parseFloat(newPriceAmount) *
        (VAT_PERCENTAGE / (VAT_PERCENTAGE + PERCENTAGE_BASE)),
    ).toFixed(2);
  }
}

export async function postParcelItemData(
  parcelItemformDataWithPrice,
  constants,
  CREATE_COLLECTION_LINE_URL,
  postDataFunc,
  responseHandlers,
) {
  /**Post data needed to create a parcel */
  try {
    let response = await postDataFunc(
      parcelItemformDataWithPrice,
      constants,
      CREATE_COLLECTION_LINE_URL,
    );
    const data = await response.json();
    responseHandlers.handleParcelItemDataResponse(
      data,
      constants,
      responseHandlers.dependencies,
    );
  } catch (error) {
    responseHandlers.handleResponseError(constants);
    console.error(error);
  }
}

export function handleParcelItemDataResponse(data, constants, dependencies) {
  dependencies.showLoadingOverlay(constants);
  let alertMessage = data.data;
  let messageType = data.message;
  if (data.message === 'error') {
    setTimeout(() => {
      dependencies.hideLoadingOverlay(constants);
    }, 500);
    dependencies.displayErrorMessage(alertMessage, constants);
  } else {
    let alertPrimaryTextContainer = constants.alertPrimaryTextContainer;
    let alertSecondaryTextContainer = constants.alertSecondaryTextContainer;
    let alertContainerWrapper = constants.alertContainerWrapper;
    setTimeout(() => {
      dependencies.hideLoadingOverlay(constants);
    }, 1000);
    dependencies.displayAlertMessage(
      alertMessage,
      messageType,
      alertPrimaryTextContainer,
      alertSecondaryTextContainer,
      alertContainerWrapper,
    );
    setTimeout(() => {
      window.location = data.success_url;
    }, 2000);
  }
}

export async function refetchParcelPriceBeforeSubmit(
  dependencies,
  fetchPriceFuncHandler,
  parcelItemformData,
  savingStrategy,
  constants,
  CREATE_COLLECTION_LINE_URL,
  taxHandlersAndArguments,
  reponseHandlers,
  createParcelItemHandler,
) {
  dependencies.showLoadingOverlay(constants);
  const priceData = {
    message: 'No Price', // there was an error fetching price so users enter price manually
  };
  reponseHandlers.handlePriceFetchResponse(
    priceData,
    parcelItemformData,
    savingStrategy,
    constants,
    CREATE_COLLECTION_LINE_URL,
    taxHandlersAndArguments,
    createParcelItemHandler,
    reponseHandlers.handlerDependencies,
  );
}

export function handlePriceFetchResponse(
  data,
  parcelItemformData,
  savingStrategy,
  constants,
  CREATE_COLLECTION_LINE_URL,
  taxHandlersAndArguments,
  createParcelItemHandler,
  handlerDependencies,
) {
  if (data.message === 'No Price') {
    handlerDependencies.handleNoPriceFetch(
      parcelItemformData,
      savingStrategy,
      constants,
      CREATE_COLLECTION_LINE_URL,
      taxHandlersAndArguments,
      createParcelItemHandler,
    );
  } else {
    handlerDependencies.handlePriceFetchSuccess(
      parcelItemformData,
      data,
      savingStrategy,
      constants,
      CREATE_COLLECTION_LINE_URL,
      createParcelItemHandler,
    );
  }
}

/** For when no price was  fetched from the ratecards , thus manual entry */
export function handleNoPriceFetch(
  parcelItemformData,
  savingStrategy,
  constants,
  CREATE_COLLECTION_LINE_URL,
  taxHandlersAndArguments,
  createParcelItemHandler,
) {
  let multiplyQuantity = false;
  let newPriceValue = constants.newPriceInput.value.trim();
  let quantityValue = constants.quantity.value.trim();
  let parcelItemData = parcelItemformData;
  parcelItemData.saveAndEditPrice = savingStrategy.saveAndEditPrice;
  parcelItemData.action_type = savingStrategy.action_type;
  parcelItemData.line_item_id = savingStrategy.line_item_id;
  parcelItemData.total_price = parseFloat(newPriceValue).toFixed(2);
  parcelItemData.unit_price = parseFloat(newPriceValue / quantityValue).toFixed(
    2,
  );
  parcelItemData.tax_amount = taxHandlersAndArguments.calculateTax(
    newPriceValue,
    multiplyQuantity,
    taxHandlersAndArguments.arguments,
  );

  parcelItemData.tax_rate = constants.orgVATTaxRateElement.textContent;
  parcelItemData.saveAsDraft = savingStrategy.saveAsDraft;
  // tax id
  let skuTaxId = constants.skuTaxContainer.innerText;
  if (skuTaxId == 'None') {
    parcelItemData.skuTaxId = '';
  } else {
    parcelItemData.skuTaxId = skuTaxId;
  }
  // parcelItemData = geotagPostData(parcelItemData);
  let requestCreateParcelData = parcelItemData;
  let reponseHandlers = createParcelItemHandler.responseHandlers;
  let postDataFunc = createParcelItemHandler.postDataFunc;

  createParcelItemHandler.postParcelItemData(
    requestCreateParcelData,
    constants,
    CREATE_COLLECTION_LINE_URL,
    postDataFunc,
    reponseHandlers,
  );
  localStorage.removeItem('parcelItemData');
  hideLoadingOverlay(constants);
}

/** For when a price was successfully fetched from the ratecards */
export function handlePriceFetchSuccess(
  parcelItemformData,
  priceData,
  savingStrategy,
  constants,
  CREATE_COLLECTION_LINE_URL,
  createParcelItemHandler,
) {
  let parcelItemData = parcelItemformData;
  let quantityValue = constants.quantity.value.trim();
  parcelItemData.saveAndEditPrice = savingStrategy.saveAndEditPrice;
  parcelItemData.saveAsDraft = savingStrategy.saveAsDraft;
  parcelItemData.action_type = savingStrategy.action_type;
  parcelItemData.line_item_id = savingStrategy.line_item_id;
  parcelItemData.unit_price = parseFloat(priceData.data.total_amount).toFixed(
    2,
  );
  parcelItemData.total_price = parseFloat(
    priceData.data.total_amount * quantityValue,
  ).toFixed(2);
  parcelItemData.tax_amount = parseFloat(
    priceData.data.tax_amount * quantityValue,
  ).toFixed(2);
  parcelItemData.rate_card_id = priceData.data.rate_card_id;
  parcelItemData.rating_table_id = priceData.data.rating_table_id;
  // tax id
  let skuTaxId = constants.skuTaxContainer.innerText;
  if (skuTaxId == 'None') {
    parcelItemData.skuTaxId = '';
  } else {
    parcelItemData.skuTaxId = skuTaxId;
  }
  // parcelItemData = geotagPostData(parcelItemData);
  let requestCreateParcelData = parcelItemData;
  let reponseHandlers = createParcelItemHandler.responseHandlers;
  let postDataFunc = createParcelItemHandler.postDataFunc;
  createParcelItemHandler.postParcelItemData(
    requestCreateParcelData,
    constants,
    CREATE_COLLECTION_LINE_URL,
    postDataFunc,
    reponseHandlers,
  );
  localStorage.removeItem('parcelItemData');
  hideLoadingOverlay(constants);
}

// Interface for form validator
export class IFormValidator {
  /**
   * Interface for form validator.
   * @throws {Error} Cannot instantiate interface.
   */
  constructor() {
    if (this.constructor === IFormValidator) {
      throw new Error('Cannot instantiate form validator interface');
    }
  }
  /**
   * Validates the form.
   * @abstract
   * @throws {Error} Method not implemented.
   */
  validateForm() {
    throw new Error('validateForm Method not implemented');
  }
}

// Abstract class for form validator
export class AbstractFormValidator extends IFormValidator {
  /**
   * Abstract class for form validator.
   * @param {object} validators - An object containing individual validation functions.
   * @param {object} formElementConstantsObj - An object containing form element constants.
   */
  constructor(validators, formElementConstantsObj) {
    super();
    this.validators = validators;
    this.formElementConstantsObj = formElementConstantsObj;
  }

  /**
   * Validates the form using individual validators.
   * @returns {boolean} True if form is valid, false otherwise.
   */
  validateForm() {
    const validationResults = Object.entries(this.validators).map(
      ([, validator]) => {
        const result = validator(this.formElementConstantsObj);
        return result;
      },
    );
    return validationResults.every(result => result);
  }
}

export class CollectionLineItemsFormValidator extends AbstractFormValidator {
  /**
   * Custom form validator class.
   * @param {object} validators - An object containing individual validation functions.
   * @param {object} formElementConstantsObj - An object containing form element constants.
   */
  constructor(validators, formElementConstantsObj) {
    super(validators, formElementConstantsObj);
  }
}
